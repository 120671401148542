import React, { ReactNode } from "react";

interface IPageSectionProps {
  additionalCssClasses?: string;
  bgColorClass?: string;
  children: ReactNode;
  hideVerticalSpacing?: boolean;
  maxWidthClass?: string;
}

export const PageSection = ({
  additionalCssClasses,
  bgColorClass,
  children,
  hideVerticalSpacing,
  maxWidthClass,
}: IPageSectionProps) => (
  <section
    className={`xl:px-32 md:px-16 px-8 pb-40 first:${
      additionalCssClasses ? `${additionalCssClasses}` : ""
    }${bgColorClass ? ` ${bgColorClass}` : ""} ${
      hideVerticalSpacing ? "" : "py-20"
    }${maxWidthClass ? " w-full flex justify-center" : ""} `}
  >
    {maxWidthClass ? <div className={maxWidthClass}>{children}</div> : children}
  </section>
);
