import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import leftArrow from "../../../assets/leftArrow.png";

import { auth } from "../../screens/Auth/firebase";
import { Company } from "../../mock/NewTable";

interface IBackNavbar {
  company?: Company;
}
export const BackNavbar = ({ company }: IBackNavbar) => {
  const history = useHistory();

  const { pathname } = useLocation();

  const logoutHandle = async () => {
    await auth.signOut();
    history.push("/");
  };

  const back = useCallback(() => {
    if (
      company &&
      pathname === `/profile/${company.name.toLowerCase()}/pattern`
    ) {
      history.push(`/profile/${company.name.toLowerCase()}`);
    } else if (
      company &&
      pathname === `/profile/${company.name.toLowerCase()}`
    ) {
      history.push(`/home`);
    } else {
      history.push(`/home`);
    }
  }, [history, pathname, company]);

  return (
    <nav className="h-35 mt-15 mb-34 pl-26 pr-14 flex items-center justify-between flex-wrap">
      <div
        className="text-darkgrey font-medium font-google flex items-center cursor-pointer"
        onClick={back}
        style={{ outline: "none" }}
      >
        <img
          src={leftArrow}
          className="w-12 mr-8"
          alt=""
          width="w-12"
          height="auto"
        />
        {company &&
        pathname === `/profile/${company.name.toLowerCase()}/pattern` ? (
          <p>{"Back to profile"}</p>
        ) : (
          <p>{"Back to dashboard"}</p>
        )}
      </div>

      <div>
        <button
          type="button"
          className="inline-block text-gray-800 border-2 border-Blue-400 w-55 h-19 font-medium rounded-full antialiased outline-none"
          onClick={logoutHandle}
          style={{ outline: "none" }}
        >
          Log Out
        </button>
      </div>
    </nav>
  );
};
