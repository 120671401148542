import React from "react";
import { PulseLoader } from "react-spinners";

interface loadingProps {
  pending?: boolean;
}
export const Loading = ({ pending = true }: loadingProps) => {
  return (
    <div className={"flex justify-center h-screen bg-white"}>
      <div className={"self-center"}>
        <PulseLoader color={"#5222d0"} loading={pending} css={""} size={50} />
      </div>
    </div>
  );
};
