import React, { useState } from "react";
import { Link } from "react-router-dom";
import Media from "react-media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../uxApp/shared/UIElement/Modal";
import homeImageA from "../../assets/Landing/homeImageA.svg";
import homeImageB from "../../assets/Landing/homeImageB.svg";
import highlight from "../../assets/Landing/dontCopy.png";

export const Home = () => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const closeHandle = () => {
    return setIsShown(false);
  };

  return (
    <div className="flex justify-center text-center relative">
      <img
        src={homeImageA}
        className="md:absolute md:left-0 md:bottom-0 lg:block hidden"
        alt=""
      />
      <img
        src={homeImageB}
        className="md:absolute md:right-0 md:bottom-0 lg:block hidden"
        alt=""
      />
      <div className="lg:order-first order-last text-center mt-10 md:m-0 lg:w-p666">
        <img src={highlight} className="mx-auto mb-8" alt="" />
        <h1 className="leading-tight md:text-8xl text-4xl text-Blue-100 font-title font-bold">
          Analyse your competitors' design
        </h1>
        <p className="md:mt-6 my-6 text-grey-200 md:text-2lg text-lg font-light">
          Add strategic insights to your design, by analysing your competitor's
          website or application design patterns.
        </p>
        <div>
          <Media queries={{ small: { maxWidth: 425 } }}>
            {(matches) =>
              matches.small ? (
                <div>
                  <Link to="/error" className="bg-transparent">
                    <button
                      type="button"
                      style={{ outline: "none" }}
                      className="bg-Red-400  text-white text-center font-title font-semibold py-2 px-4 rounded-full w-108 mt-12 h-30  shadow text-lg"
                    >
                      Free Sign Up
                    </button>
                  </Link>
                </div>
              ) : (
                <div className={"flex flex-row items-center justify-center"}>
                  <Link to="/join" className="bg-transparent">
                    <button
                      type="button"
                      style={{ outline: "none" }}
                      className="bg-Red-400 hover:bg-purpleblue text-white text-center font-title font-semibold py-2 px-4 rounded-full w-108 mt-10 h-30 mr-12 shadow text-lg"
                    >
                      {"Try it now - It's free"}
                    </button>
                  </Link>

                  <Modal
                    show={isShown}
                    onCancel={closeHandle}
                    onKeyPress={closeHandle}
                  >
                    <button
                      onClick={closeHandle}
                      className=" ml-auto cursor-pointer outline-none relative my-2"
                      style={{ left: "98%" }}
                      type="button"
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-darkblue"
                        size="2x"
                      />
                    </button>
                  </Modal>
                </div>
              )
            }
          </Media>
        </div>
      </div>
    </div>
  );
};
