import React from "react";
import { Image } from "../../../uxApp/shared/UIElement/image";
import startupImage from "../../../assets/Landing/ImageStartupTips.svg";
import iconBlue from "../../../assets/Landing/tipsIconBlue.svg";
import iconRed from "../../../assets/Landing/tipsIconRed.svg";
import twiggleArrow from "../../../assets/Landing/twiggleArrow.svg";
import {
  FlexContainer,
  FlexContentJustify,
} from "../../../uxApp/shared/style components/FlexContainer";

interface Data {
  icon: string;
  title: string;
  paragraph: string;
}
export const StartupTips = () => {
  const tips: Data[] = [
    {
      icon: iconBlue,
      title: "Explainer video as MVP",
      paragraph:
        "No functional prototype yet? You can always create a short video that describes what your product does by using a few design screens to validate your idea.",
    },
    {
      icon: iconRed,
      title: "Crash test your idea",
      paragraph:
        "Give yourself a chance to test your design. Your first product design should be a test/research to validate what works and what doesn't.",
    },
  ];
  return (
    <div className="flex lg:flex-row flex-col items-center">
      <img
        src={twiggleArrow}
        className="absolute lg:block hidden"
        style={{
          left: "402px",
          bottom: "150px",
        }}
        alt=" "
      />
      <Image
        additionalCssClasses={""}
        width={"lg:w-p416 w-p290"}
        height={"h-full"}
        src={startupImage}
        alt="photo"
        lazy={true}
      />
      <div
        style={{ maxWidth: "325px" }}
        className="xl:ml-61 lg:ml-30 lg:mt-0 mt-18"
      >
        <h2 className="text-Blue-100 font-title lg:text-left text-center text-4lg xl:mb-18 mb-16 font-bold leading-tight">
          Tips for non-technical founders
        </h2>
        {tips.map((tip, index) => (
          <FlexContainer
            isVertical
            additionalCssClasses={`rounded-2xl bg-Blue-900 py-8 pl-10 pr-4  xl:mb-20 lg:mb-10 mb-8 last:mb-0`}
            contentJustify={FlexContentJustify.BETWEEN}
            key={index}
          >
            <div className="inline-flex justify-between">
              <p className="text-Blue-100 font-title text-2lg font-bold">
                {tip.title}
              </p>
              <img src={tip.icon} alt="icon" />
            </div>
            <p className="text-Blue-100 mb-5">{tip.paragraph}</p>
          </FlexContainer>
        ))}
      </div>
    </div>
  );
};
