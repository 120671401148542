import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  RefObject,
  forwardRef,
} from "react";

export enum FlexItemsAlign {
  STRETCHED = "items-stretched",
  START = "items-start",
  CENTER = "items-center",
  END = "items-end",
  BASELINE = "items-baseline",
}

export enum FlexContentAlign {
  START = "content-start",
  CENTER = "content-center",
  END = "content-end",
  BETWEEN = "content-between",
  AROUND = "content-around",
}

export enum FlexContentJustify {
  START = "justify-start",
  CENTER = "justify-center",
  END = "justify-end",
  BETWEEN = "justify-between",
  AROUND = "justify-around",
}

interface IFlexContainerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  additionalCssClasses?: string;
  children: ReactNode;
  contentAlign?: FlexContentAlign;
  contentJustify?: FlexContentJustify;
  doWrap?: boolean;
  hasMaxWidth?: boolean;
  isVertical?: boolean;
  itemsAlign?: FlexItemsAlign;
}

export const FlexContainer = forwardRef(
  (
    {
      additionalCssClasses,
      children,
      contentAlign,
      contentJustify,
      doWrap = false,
      hasMaxWidth = false,
      isVertical,
      itemsAlign,
      ...other
    }: IFlexContainerProps,
    ref
  ) => {
    let cssClasses = `flex ${isVertical ? "flex-col" : "flex-row"} ${
      hasMaxWidth ? " container" : ""
    }`;
    if (doWrap) {
      cssClasses += ` flex-wrap`;
    }
    if (additionalCssClasses) {
      cssClasses += ` ${additionalCssClasses}`;
    }
    if (contentAlign) {
      cssClasses += ` ${contentAlign}`;
    }
    if (contentJustify) {
      cssClasses += ` ${contentJustify}`;
    }
    if (itemsAlign) {
      cssClasses += ` ${itemsAlign}`;
    }
    return (
      <div
        className={cssClasses}
        ref={ref as RefObject<HTMLDivElement>}
        {...other}
      >
        {children}
      </div>
    );
  }
);
