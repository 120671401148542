import React, { useEffect, useState, createContext, ReactNode } from "react";
import { auth, USER } from "../../screens/Auth/firebase";
import { Loading } from "../Loading";

interface AuthProviderProps {
  children: ReactNode;
}
export const AuthContext = createContext<USER>(null);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<USER>(null);

  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      setPending(false);
    });
  }, [currentUser]);

  if (pending) {
    return <Loading pending={pending} />;
  }

  return (
    <AuthContext.Provider value={currentUser}>{children}</AuthContext.Provider>
  );
};
