import React, { useState } from "react";
import { FlexContainer } from "../../../uxApp/shared/style components/FlexContainer";
import oodle from "./img/comp1.svg";
import mMcLennan from "./img/MarshMcLennan.svg";
import stellantis from "./img/Stellantis.svg";
import vhi from "./img/vhi.svg";
import pfizer from "./img/pfizer.svg";
import Section from "../../../uxApp/shared/section";

interface Data {
  name: string;
  title: string;
  description: any;
  image: string;
}
export const Companies = () => {
  const [section, setSection] = useState<string>("oodle");

  const isOpenSection = (name: string) => section === name;

  const toggleSection = (name: string) => {
    setSection(name);
  };
  const data: Data[] = [
    {
      name: "oodle",
      title: "Competitive Analysis & Design",
      description: (
        <p>
          Conducted competitive analysis and design for{" "}
          <a
            href="https://www.oodlecarfinance.com"
            className="underline text-yellowOrange cursor-pointer"
          >
            Oodle website
          </a>
          .
        </p>
      ),
      image: oodle,
    },
    {
      name: "mMcLennan",
      title: "Competitive Analysis",
      description: (
        <p>
          Designed and conducted competitive analysis for{" "}
          <a
            href="https://brain.mercer.com"
            className="underline text-yellowOrange cursor-pointer"
          >
            Mercer brain
          </a>
          , a content management system and search engine.
        </p>
      ),
      image: mMcLennan,
    },
    {
      name: "stellantis",
      title: "Competitive Analysis & UX",
      description: (
        <p>
          Conducted competitive analysis and UX design for{" "}
          <a
            href="https://www.stellantisfinancialservices.co.uk/drivers-hub"
            className="underline text-yellowOrange cursor-pointer"
          >
            the Driver's Hub
          </a>
          .
        </p>
      ),
      image: stellantis,
    },
    {
      name: "vhi",
      title: "Competitive & Behavioral Analysis",
      description: (
        <p>
          Conducted competitive and consumer behavior analysis as part of VHI
          innovation strategy for{" "}
          <a
            href="https://apps.apple.com/us/app/vhi/id1266282746?l=lv&ls=1"
            className="underline text-yellowOrange cursor-pointer"
          >
            Health App
          </a>
          .
        </p>
      ),
      image: vhi,
    },
    {
      name: "pfizer",
      title: "Product Design",
      description: (
        <p>
          <a
            href="https://claims.pfizerpro.co.uk"
            className="underline text-yellowOrange cursor-pointer"
          >
            Watch video
          </a>{" "}
          of Off-Contract Claim to see work.
        </p>
      ),
      image: pfizer,
    },
  ];

  return (
    <div className=" flex  lg:flex-row flex-col lg:justify-around items-center lg:ml-28">
      <FlexContainer
        isVertical
        additionalCssClasses={"text-white lg:ml-48 lg:mr-50"}
      >
        <h2 className="md:text-5xl text-2xl text-white font-title font-bold text-center lg:mb-20 mb-24 leading-tight">
          We've applied UX analysis to their product
        </h2>

        <nav className="flex flex-wrap flex-row items-center justify-start lg:justify-center">
          {data.map((comp, index) => (
            <div
              key={`${index}-${comp}`}
              onClick={() => toggleSection(comp.name)}
              onKeyPress={() => toggleSection(comp.name)}
              tabIndex={0}
              role="button"
              className={`flex min-h-28 rounded-full lg:my-0 my-4 mx-4 px-8 py-4 justify-center ${
                isOpenSection(comp.name)
                  ? "bg-purpleblue text-white outline-none"
                  : " bg-transparent border-2 text-grey-985 border-gray-400 border-solid"
              } outline-none`}
            >
              <img src={comp.image} alt={`${comp.name} logo`} />
            </div>
          ))}
        </nav>
        <div className="rounded-customSmall p-30 bg-grey-50 text-center mt-24">
          {data.map((comp, index) => (
            <div key={`${comp.name}-${index}`}>
              <Section isOpen={isOpenSection(comp.name)}>
                <p className="text-2lg text-center font-semibold font-title mb-4">
                  {comp.title}
                </p>
                {comp.description}
              </Section>
            </div>
          ))}
        </div>
      </FlexContainer>
    </div>
  );
};
