import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { Error } from "./uxApp/error";
import { Landing } from "./Landing/Pages/landing";
import { AuthProvider } from "./uxApp/shared/context/AuthContext";

import PrivateRoute from "./uxApp/shared/context/PrivateRoute";
import { ModalProvider } from "./uxApp/shared/modal/modalContext";
import { PatternPage } from "./uxApp/screens/CompanyPattern/PatternPage";
import ScrollToTop from "./uxApp/shared/style components/ScrollToTop";
import {
  collection,
  db,
  onSnapshot,
  useAuth,
} from "./uxApp/screens/Auth/firebase";
import { Company, LikedCompanies } from "./uxApp/mock/NewTable";
import { Loading } from "./uxApp/shared/Loading";

const Join = React.lazy(() => import("./uxApp/screens/Auth/Auth"));
const Pricing = React.lazy(() => import("./Landing/Pages/Pricing"));
const TermServices = React.lazy(
  () => import("./Landing/Components/Term&Services")
);
const Glossary = React.lazy(() => import("./uxApp/glossary"));
const Favorites = React.lazy(() => import("./uxApp/favorites"));
const MainProfile = React.lazy(() => import("./uxApp/mainProfile"));
const Home = React.lazy(() => import("./uxApp/Home"));

const App = () => {
  const userProfile = useAuth();

  const [companies, setCompanies] = useState<Company[]>([]);
  const [dataLikes, setDataLikes] = useState<LikedCompanies[]>([]);
  const [userSavedCompanies, setuserSavedCompanies] = useState<Company[]>([]);

  useEffect(() => {
    const getCompanies = async () => {
      const companiesCollectionRef = collection(db, "companiesLists");
      onSnapshot(companiesCollectionRef, (querySnapshot) => {
        const cod = querySnapshot.docs.map((doc) => doc.data()) as Company[];
        setCompanies(cod);
      });
    };

    const getUserProfile = async () => {
      if (userProfile) {
        const userLikesCollectionRef = collection(
          db,
          "users",
          userProfile.uid,
          "likes"
        );

        onSnapshot(userLikesCollectionRef, (querySnapshot) => {
          const dataLikes = querySnapshot.docs.map((doc) =>
            doc.data()
          ) as LikedCompanies[];

          setDataLikes(dataLikes);
        });
      }
    };
    const getUserFavorites = async () => {
      if (userProfile) {
        const userFavoritesCollectionRef = collection(
          db,
          "users",
          userProfile.uid,
          "favorites"
        );
        onSnapshot(userFavoritesCollectionRef, (querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => doc.data()) as Company[];
          setuserSavedCompanies(data);
        });
      }
    };

    getCompanies();
    getUserProfile();
    getUserFavorites();
  }, [userProfile]);

  return (
    <div className="bg-lightGrayBg h-full">
      <AuthProvider>
        <ModalProvider>
          <Router>
            <ScrollToTop />
            <Switch>
              <Route path="/" exact component={Landing} />
              <Route path="/privacy" exact>
                <Suspense fallback={<Loading />}>
                  <TermServices />
                </Suspense>
              </Route>
              <Route path="/pricing" exact>
                <Suspense fallback={<Loading />}>
                  <Pricing />
                </Suspense>
              </Route>
              <Route path="/join" exact>
                <Suspense fallback={<Loading />}>
                  <Join />
                </Suspense>
              </Route>
              <Route path="/error" exact component={Error} />

              <PrivateRoute path="/home" exact>
                <Suspense fallback={<Loading />}>
                  <Home companies={companies} />
                </Suspense>
              </PrivateRoute>
              <PrivateRoute path="/profile/:compagnie" exact>
                <Suspense fallback={<Loading />}>
                  <MainProfile
                    companies={companies}
                    likes={dataLikes}
                    favorites={userSavedCompanies}
                  />{" "}
                </Suspense>
              </PrivateRoute>
              <PrivateRoute path="/profile/:compagnie/pattern" exact>
                <PatternPage companies={companies} />
              </PrivateRoute>
              <PrivateRoute path="/glossary" exact>
                <Suspense fallback={<Loading />}>
                  <Glossary companies={companies} />
                </Suspense>
              </PrivateRoute>
              <PrivateRoute path="/favorites" exact>
                <Suspense fallback={<Loading />}>
                  <Favorites
                    favorites={userSavedCompanies}
                    companies={companies}
                  />
                </Suspense>
              </PrivateRoute>
              <Redirect to="/" />
            </Switch>
          </Router>
        </ModalProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
