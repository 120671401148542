import React, { ReactNode } from "react";

export interface IFullScreenBarProps {
  additionalCssClasses?: string;
  bgColor?: string;
  children?: ReactNode;
}

export const FullScreenBar = ({
  additionalCssClasses,
  bgColor,
  children,
}: IFullScreenBarProps) => {
  return (
    <div
      className={`w-full flex items-center justify-between  ${bgColor} ${
        additionalCssClasses ? `${additionalCssClasses}` : ""
      }`}
    >
      {children}
    </div>
  );
};
