import React, { Fragment } from "react";
import { Image } from "../../../uxApp/shared/UIElement/image";
import designImg from "../../../assets/Landing/designAdviceImg.png";
import designIdea from "../../../assets/Landing/designIdea.png";
import icon from "../../../assets/Landing/designAdviceIcon.svg";
import imageA from "../../../assets/Landing/designAdviceImageA.svg";

export const DesignAdvice = () => {
  return (
    <>
      <div className="flex md:flex-row flex-col items-center justify-between mt-61 bg-grey-20 md:p-24 p-12">
        <img
          src={icon}
          className="absolute inset-x-1/2"
          style={{
            top: "-30px",
          }}
          alt=""
        />
        <div className="flex flex-col">
          <h2 className="text-white font-title text-4lg leading-tight font-bold mb-22 lg:mt-0 mt-30">
            Why include competitive analysis in your design?
          </h2>
          <div className="flex lg:flex-row flex-col">
            <p className="text-white border-l-2 pl-8 border-Purple-400 sm:w-p300 w-full xl:mr-24 lg:mr-12 lg:mb-0 mb-20">
              A company's positioning and differentiation can be improved by
              analyzing other products.
            </p>
            <p className="text-white border-l-2 pl-8 border-Purple-400 sm:w-p334 w-full lg:mr-12">
              Providing examples of how a pattern/design improved a competitor's
              product will make building a business case for your product much
              easier.
            </p>
          </div>
        </div>
        <Image
          additionalCssClasses={"xl:mr-25 lg:mt-0 md:mt-61 mx-auto mt-24"}
          width={"lg:w-full w-p288"}
          height={"h-auto"}
          src={designImg}
          alt="photo"
          lazy={true}
        />
      </div>
      <div className="flex sm:flex-row flex-col items-center md:mt-30 mt:20">
        {" "}
        <div
          className="flex flex-col bg-grey-1035 justify-center w-full px-16 pt-20 mt-12 sm:mt-0 pb-25 sm:mr-8"
          style={{ height: "516px" }}
        >
          {" "}
          <Image
            additionalCssClasses={""}
            width={"w-full"}
            height={"h-full"}
            src={imageA}
            alt="photo"
            lazy={true}
            style={{ maxWidth: "368px" }}
            imgClass={"m-auto"}
          />{" "}
          <h2 className="text-Blue-100 font-title text-4lg leading-tight font-bold mb-8">
            {"Lets Kiss!"}
          </h2>
          <p className="text-grey-500">
            {
              "Keep it Super Simple. Keeping your design simple at an early stage makes it easier to redesign or change your product based on early feedback. So don't worry about getting everything perfect right away."
            }
          </p>
        </div>
        <div
          className="flex flex-col bg-Blue-970 justify-center w-full mt-12 sm:mt-0 sm:ml-8 px-16 pt-20 pb-25"
          style={{ height: "516px" }}
        >
          {" "}
          <Image
            width={"w-full"}
            height={"h-full"}
            src={designIdea}
            alt="photo"
            lazy={true}
            style={{ maxWidth: "242px" }}
            imgClass={"m-auto rounded-2xl border-2 border-Purple-875 p-5"}
          />
          <h2 className="text-Blue-100 font-title text-4lg leading-tight font-bold mb-8 mt-28">
            {"Emotional affordance?"}
          </h2>
          <p className="text-grey-500">
            {
              "The idea is to design products that add emotional impact. An example would be design aesthetics or micro-interactions within a significant task."
            }
          </p>
          <p className="text-Purple-875">
            Example:{" "}
            <a
              href="https://www.philosophicalfoxes.com/"
              rel="noreferrer"
              target="_blank"
              className="visited:text-Purple-520 underline"
            >
              Philosophicalfoxes
            </a>
          </p>
        </div>{" "}
      </div>
    </>
  );
};
