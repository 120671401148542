import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { AuthContext } from "./AuthContext";

interface PrivateRouteProps {
  children?: React.ReactNode;
  path: RouteProps["path"];
  exact: RouteProps["exact"];
}
const PrivateRoute = ({
  children,
  ...routeProps
}: PrivateRouteProps) => {
  const currentUser = useContext(AuthContext);
  return (
    <Route
      {...routeProps}
      render={(props) =>
        !!currentUser ? <Route>{children}</Route> : <Redirect to={"/join"} />
      }
    />
  );
};

export default PrivateRoute;
