import React, { Fragment, useState } from "react";
import Section from "../../../uxApp/shared/section";
import { Offers } from "./offers";
import { Companies } from "./companies";

export const DesignResearch = () => {
  const [section, setSection] = useState<string>("Offers");

  const isOpenSection = (name: string) => section === name;

  const toggleSection = (name: string) => {
    setSection(name);
  };

  return (
    <Fragment>
      <nav className="flex flex-row items-center justify-center lg:mt-40 mt-35 mb-30 lg:order-1 order-2">
        <div
          onClick={() => toggleSection("Offers")}
          onKeyPress={() => toggleSection("Offers")}
          tabIndex={0}
          role="button"
          className={`flex items-center rounded-full mx-4 px-8 py-4 min-h-22 ${
            isOpenSection("Offers")
              ? "bg-purpleblue  outline-none"
              : " bg-transparent border-2  border-white border-solid"
          } outline-none text-white`}
        >
          Offers
        </div>

        <div
          onClick={() => toggleSection("Companies")}
          onKeyPress={() => toggleSection("Companies")}
          tabIndex={0}
          role="button"
          className={`flex items-center rounded-full mx-4 px-8 py-4 min-h-22 ${
            isOpenSection("Companies")
              ? "bg-purpleblue outline-none"
              : " bg-transparent border-2 border-white border-solid"
          } outline-none text-white`}
        >
          Companies
        </div>
      </nav>
      <div className="order-3">
        <div className="relative lg:ml-7">
          <Section isOpen={isOpenSection("Offers")}>
            <Offers />
          </Section>
        </div>
        <div className="relative lg:ml-7">
          <Section isOpen={isOpenSection("Companies")}>
            <Companies />
          </Section>
        </div>
      </div>
    </Fragment>
  );
};
