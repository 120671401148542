import React, { Fragment } from "react";
import { Home } from "../Components/home";
import { Footer } from "../shared/footer";
import { UxLandingTopBar } from "../shared/UxLandingTopBar";
import { WhyUs } from "../Components/whyUs";
import { AboutUs } from "../Components/aboutUs";
import { PageSection } from "../../uxApp/shared/style components/PageSection";
import { WhatWeOffer } from "../Components/whatWeOffer";
import { DesignPractices } from "../Components/designPractices/designPractices";
import { DesignResearch } from "../Components/designResearch/designResearch";
export const Landing = () => {
  return (
    <Fragment>
      <UxLandingTopBar hasWideMargins additionalCssClasses={"pt-14 pb-20"} />
      <PageSection
        hideVerticalSpacing={false}
        bgColorClass={"bg-grey-955"}
        additionalCssClasses={"relative"}
      >
        <Home />
      </PageSection>
      <PageSection hideVerticalSpacing bgColorClass={"bg-grey-955"}>
        <WhyUs />
      </PageSection>
      <PageSection
        hideVerticalSpacing={false}
        bgColorClass={"bg-grey-20"}
        additionalCssClasses={""}
      >
        <WhatWeOffer />
      </PageSection>
      <PageSection
        hideVerticalSpacing={false}
        bgColorClass={"bg-grey-955"}
        additionalCssClasses={""}
      >
        <DesignPractices />
      </PageSection>
      <PageSection hideVerticalSpacing={false} bgColorClass={"bg-grey-20"}>
        <DesignResearch />
      </PageSection>
      <PageSection
        hideVerticalSpacing={false}
        bgColorClass={"bg-grey-955"}
        additionalCssClasses={""}
      >
        <AboutUs />
      </PageSection>
      <div className="flex-grow-0 flex-shrink-0 ">
        <Footer />
      </div>
    </Fragment>
  );
};
