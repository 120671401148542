import React from "react";
import Media from "react-media";

import computer from "../../assets/Landing/computerBlack.png";

export const AboutUs = () => {
  return (
    <div className="">
      <h2 className="text-Blue-100 font-title md:text-5xl text-2xl mb-8 font-bold text-center ">
        {"Why we built UX Analytic?"}
      </h2>
      <p
        className="text-grey-200 lg:text-lg text-base text-center mb-20 mx-auto"
        style={{ maxWidth: "640px" }}
      >
        {
          "UX Analytic's mission stems from the desire to provide founders and creators with design insights behind successful products and services. It’s for people who want inspiration and anyone who wants to design better software. Skål 🍻"
        }
      </p>
      <Media queries={{ small: { maxWidth: 768 } }}>
        {(matches) =>
          matches.small ? (
            <div className={"flex flex-row items-center justify-center"}>
              <button
                type="button"
                style={{ outline: "none" }}
                className="bg-black text-white text-center font-title font-semibold  rounded-full w-fit px-12 mt-12 h-27  shadow text-lg relative"
              >
                <img src={computer} className="absolute left-12" alt="" />
                <p className="ml-16">To try - use computer</p>
              </button>
            </div>
          ) : (
            <div>
              <button
                type="button"
                style={{ outline: "none" }}
                className="bg-Red-400 hover:bg-purpleblue text-white text-center flex justify-center m-auto font-title font-semibold rounded-full w-fit h-27 py-8 px-12 shadow text-lg"
              >
                {"Try it now - It's free"}
              </button>
            </div>
          )
        }
      </Media>
    </div>
  );
};
