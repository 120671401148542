import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import lab from "../../../assets/lab-flask.svg";
import book from "../../../assets/book.svg";

import logo from "../../../assets/uxanalytic-logo.svg";
import icon from "../../../assets/icons.svg";
import { Tooltip } from "../UIElement/tooltip";

export const Sidebar = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isDashboardVisible, setIsDashboardVisible] = useState<boolean>(false);
  const [isGlossaryVisible, setIsGlossaryVisible] = useState<boolean>(false);
  return (
    <div className="flex lg:flex-col md:flex-row md:justify-between lg:justify-start lg:h-full w-full lg:fixed top-0 lg:left-0 bg-white overflow-x-hidden lg:w-36 z-10 lg:pb-0 pb-5 shadow-md">
      <div className="w-20 mx-auto mt-20">
        <img
          src={logo}
          alt="logo"
          className="w-full"
          width="40px"
          height="34px"
        />
      </div>

      <div className="flex flex-col lg:mt-56">
        <NavLink
          to={`/home`}
          onMouseEnter={() => setIsDashboardVisible(true)}
          onMouseLeave={() => setIsDashboardVisible(false)}
          activeClassName="bg-purpleblue"
          className="bg-gray-400 hover:bg-purpleblue bg-transparent rounded-lg self-center w-20 h-20 mb-20"
        >
          {isDashboardVisible && (
            <Tooltip text={"Dashboard"} width={"-20"} isSidebar />
          )}
          <img
            src={icon}
            alt="icon"
            className="mx-auto relative top-1/4"
            width="13px"
            height="20px"
          />
        </NavLink>
        <NavLink
          to={`/favorites`}
          strict
          activeClassName="bg-purpleblue"
          onMouseEnter={() => setIsVisible(true)}
          onMouseLeave={() => setIsVisible(false)}
          className="bg-gray-400 hover:bg-purpleblue bg-transparent text-center rounded-lg self-center w-20 h-20 mb-20"
        >
          {isVisible && <Tooltip text={"Your Lab"} width={"-20"} isSidebar />}
          <img
            src={lab}
            alt="icon"
            className="mx-auto relative top-1/4"
            width="20px"
            height="20px"
          />
        </NavLink>
        <NavLink
          to={`/glossary`}
          strict
          onMouseEnter={() => setIsGlossaryVisible(true)}
          onMouseLeave={() => setIsGlossaryVisible(false)}
          activeClassName="bg-purpleblue"
          className="bg-gray-400 hover:bg-purpleblue bg-transparent text-center rounded-lg self-center w-20 h-20 "
        >
          {isGlossaryVisible && (
            <Tooltip text={"Glossary"} width={"-20"} isSidebar />
          )}
          <img
            src={book}
            alt="icon"
            className="mx-auto relative top-1/4"
            width="24px"
            height="18px"
          />
        </NavLink>
      </div>
    </div>
  );
};
