import React, { useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { Pattern } from "../../mock/NewTable";
import { useModal } from "../../shared/modal/modalContext";
import { Card } from "../../shared/UIElement/Card";

interface ICompanyPatternProps {
  layout: Pattern;
  index: number;
}
export const PatternModal = ({ layout, index }: ICompanyPatternProps) => {
  const { isVisible, showModal, hideModal, modalContainerId } = useModal();
  const [referenceElement, setReferenceElement] = useState<Element | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const popperContainer =
    typeof document !== "undefined"
      ? document.querySelector(`#${modalContainerId}`)
      : null;

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",

        options: {
          offset: [0, 10],
        },
      },
    ],
  });
  return (
    <div className={"text-center"}>
      <button
        ref={setReferenceElement}
        className={
          " border-2 border-Purple-400 hover:bg-Purple-400 bg-white  text-gray-800 hover:text-white sm:w-80  m-12 text-center rounded-full py-4 cursor-pointer"
        }
        onClick={isVisible ? () => hideModal() : showModal}
      >
        {"View IA & Layout"}
      </button>
      {isVisible &&
        popperContainer &&
        createPortal(
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <Card className={"flex flex-row"} isWhite>
              {layout.layout.map((layout, index) => (
                <div
                  className={
                    " bg-Purple-400 flex flex-col rounded-lg m-5 p-10 w-p475"
                  }
                  key={index}
                >
                  <div className=" self-center mb-8 h-35 w-full">
                    <img
                      src={layout.icon}
                      className={"mx-auto h-35"}
                      alt=""
                      width="auto"
                      height="h-35"
                    />
                  </div>

                  <div className={"flex flex-col  text-white text-center"}>
                    <p className={"font-title font-semibold text-white mb-8"}>
                      {layout.title}
                    </p>
                    <p className={" text-sm text-white"}>
                      {layout.description}
                    </p>
                  </div>
                </div>
              ))}
            </Card>
          </div>,
          popperContainer
        )}
    </div>
  );
};
