import React, { useState } from "react";
import { Image } from "../../uxApp/shared/UIElement/image";
import image1 from "../../assets/Landing/m1.jpg";
import image2 from "../../assets/Landing/m2.jpg";
import image3 from "../../assets/Landing/m3.jpg";
import highlightA from "../../assets/Landing/HighlightA.png";
import highlightB from "../../assets/Landing/HighlightB.png";
import {
  FlexContainer,
  FlexContentJustify,
} from "../../uxApp/shared/style components/FlexContainer";

interface Data {
  title: string;
  paragraph: string;
  img: string;
}
export const WhatWeOffer = () => {
  const offer: Data[] = [
    {
      title: "Spot Patterns",
      paragraph:
        "Spot UX patterns used by top companies to uncover valuable design practices for your project.",
      img: image3,
    },
    {
      title: "Browse Apps",
      paragraph: "View screenshots of mobile applications with UX annotations.",
      img: image1,
    },
    {
      title: "Save Companies",
      paragraph:
        "Save companies that inspire you, for brainstorming by using design planning templates from top tech companies.",
      img: image2,
    },
  ];

  const [currentScreenshot, setCurrentScreenshot] = useState<string>(
    offer[0].img
  );

  return (
    <div className="xl:ml-28 relative">
      <img
        src={highlightA}
        className="lg:absolute lg:block hidden"
        style={{
          left: "-11px",
          bottom: "-30px",
        }}
        alt=""
      />
      <img
        src={highlightB}
        className="lg:absolute lg:block hidden"
        style={{
          right: "43%",
          top: "80px",
        }}
        alt=""
      />
      <p className="text-Purple-825 xl:ml-16 lg:text-left text-center">
        {"Our Features"}
      </p>
      <h2 className="md:text-5xl text-2xl text-white font-title font-bold lg:text-left text-center xl:mb-12 lg:mb-3 mb-12 xl:ml-16 ">
        {"What we offer"}
      </h2>

      <div
        className={
          "xl:ml-16 flex lg:flex-row flex-col text-white lg:justify-center xl:items-start lg:items-center justify-start"
        }
      >
        <Image
          additionalCssClasses={
            "rounded-2xl border-2 border-Purple-875 p-10 grow"
          }
          width={"w-p600"}
          height={"h-full"}
          src={currentScreenshot}
          alt="photo"
          lazy={true}
        />

        <div
          className={
            "flex flex-col lg:ml-16 grow-0 lg:mt-0 mt-16 lg:mx-0 mx-auto"
          }
        >
          {offer.map((data, index) => (
            <FlexContainer
              isVertical
              additionalCssClasses={`rounded-2xl hover:bg-grey-50 ${
                currentScreenshot === data.img && "bg-grey-50"
              } py-8 pl-12 xl:pr-61 pr-12 mb-5 cursor-pointer`}
              contentJustify={FlexContentJustify.BETWEEN}
              key={index}
              onClick={() => setCurrentScreenshot(data.img)}
              style={{ maxWidth: "529px" }}
            >
              <div className={"text-left font-title font-bold text-2lg mb-2"}>
                {data.title}
              </div>
              <div className={"text-left font-light"}>{data.paragraph}</div>
            </FlexContainer>
          ))}
        </div>
      </div>
    </div>
  );
};
