import React from "react";
import { animated, useSpring } from "react-spring";
import { Company } from "../../mock/NewTable";
import { Card } from "../../shared/UIElement/Card";
import { PatternModal } from "./PatternModal";
import { Image } from "../../shared/UIElement/image";

interface ICompanyPatternProps {
  company: Company;
}

export const CompanyPattern = ({ company }: ICompanyPatternProps) => {
  const style = useSpring<{ width: number; opacity: number }>({
    from: { width: 685, opacity: 0 },
    to: { width: 885, opacity: 1 },
  });
  return (
    <div className={"pl-24 pr-14 flex flex-col "}>
      <div className={"flex flex-col text-center mx-auto mb-32"}>
        <Image
          additionalCssClasses={"mx-auto mb-16"}
          src={company.profile.logo}
          alt={`${company.name} logo`}
          width={"w-70"}
          height={"h-70"}
        />
        <p
          className={"font-title text-3xl text-Blue-100 font-semibold mb-3"}
        >{`${company.name} pattern`}</p>
        <p
          className={"w-p475 text-gray-800"}
        >{`We have annotated where the design patterns take place to help you think through how the site is layed out and going to work.`}</p>
      </div>

      <div className={"flex flex-col justify-center"}>
        {company.profile.pattern.map((pattern, index) => (
          <div key={index} className={"self-center"}>
            <PatternModal layout={pattern} index={index} />
            <animated.div style={style}>
              <Card className={"self-center p-12"} isWhite>
                <Image
                  src={pattern.img}
                  additionalCssClasses={"mx-auto"}
                  alt={`${company.name} patern`}
                  width={"w-p885"}
                  height={"h-full"}
                  lazy={true}
                />
              </Card>
            </animated.div>
          </div>
        ))}
      </div>
    </div>
  );
};
