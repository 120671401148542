import React, { ReactNode } from "react";
import Media from "react-media";
import { Link, useHistory } from "react-router-dom";
import { FullScreenBar } from "./FullScreenBar";
import logo from "../../assets/uxanalytic-logo.svg";
interface IUxLandingTopBarProps {
  burgerNavigation?: {
    isVisible: boolean;
    onMenuClick: (visible: boolean) => void;
  };
  additionalCssClasses?: string;
  additionalLogo?: string;
  additionalLogoSizeClasses?: string;
  logoLink?: string;
  children?: ReactNode;
  hasWideMargins?: boolean;
}

export const UxLandingTopBar = ({
  additionalCssClasses,
  hasWideMargins,
}: IUxLandingTopBarProps) => {
  let history = useHistory();
  function handleClick() {
    history.push("/");
  }
  return (
    <FullScreenBar
      additionalCssClasses={`px-4 px-8 sm:px-16 ${
        hasWideMargins ? "lg:px-32 xl:px-64" : ""
      } z-40 ${additionalCssClasses ? ` ${additionalCssClasses}` : ""}`}
      bgColor={"bg-grey-955"}
    >
      <div
        className={
          "md:mr-24 bg-Blue-650 w-57 py-3 rounded-md text-center text-Blue-350 font-bold"
        }
      >
        {"BETA"}
      </div>
      <button
        className="flex items-center flex-shrink-0 text-white md:px-32 px-20 cursor-pointer outline-none focus:outline-none"
        onClick={handleClick}
      >
        <img src={logo} alt="logo" className={"w-20 h-17"} />
      </button>

      <Media queries={{ medium: { maxWidth: 768 } }}>
        {(matches) =>
          matches.medium ? (
            <Link
              to="/error"
              className={
                "md:ml-24 rounded-full w-44 py-2 hover:bg-white hover:text-black hover:border-solid hover:border-2 hover:border-black bg-Blue-400 text-center text-white"
              }
            >
              {"Sign In"}
            </Link>
          ) : (
            <Link
              to="/join"
              className={
                "md:ml-24 rounded-full w-44 py-2 hover:bg-white hover:text-black hover:border-solid border-2 hover:border-black border-Blue-400 bg-Blue-400 text-center text-white"
              }
            >
              {"Sign In"}
            </Link>
          )
        }
      </Media>
    </FullScreenBar>
  );
};
