import React from "react";
import { Image } from "../../../uxApp/shared/UIElement/image";
import competitorsImage from "../../../assets/Landing/competitors.svg";
import { FlexContainer } from "../../../uxApp/shared/style components/FlexContainer";

import { NavLink } from "react-router-dom";

export const Offers = () => {
  const designResearch = [
    "Responsive web design (RWD)",
    "Identify Design Practices",
    "Behavioural and Usability Insights",
    "Design",
  ];
  return (
    <div className=" flex lg:flex-row flex-col lg:justify-around items-center lg:ml-28">
      <Image
        width={"w-full"}
        height={"h-full"}
        src={competitorsImage}
        alt="photo"
        lazy={true}
      />
      <FlexContainer
        isVertical
        additionalCssClasses={"text-white lg:ml-48 lg:w-6/12 lg:mr-50"}
      >
        <h2 className="md:text-5xl text-2xl text-white font-title font-bold text-left mb-12 leading-tight">
          Get product design driven by statistical analysis
        </h2>
        <p className="mb-12">
          Our data allow us to deliver an in-depth objective view of your
          competitors' products. So that we can convert our insight into
          actionable design solutions and research-backed report for you. What
          we offer but are not limited to:
        </p>
        <ul className={" list-disc flex flex-wrap list-inside"}>
          {designResearch.map((data, index) => (
            <li
              className="rounded-full py-4 px-8 border-2 border-white mr-4 mb-4 last:mr-0 "
              key={index}
            >
              {data}
            </li>
          ))}
        </ul>
        <NavLink to={`/pricing`}>
          <button
            type="button"
            style={{ outline: "none" }}
            className="bg-black hover:bg-white hover:text-black hover:border-solid border-2 border-black hover:border-black text-white text-center font-title font-semibold  rounded-full w-p250 mt-12 h-27  shadow text-lg"
          >
            {"View Pricing"}
          </button>
        </NavLink>
      </FlexContainer>
    </div>
  );
};
