import React from "react";

interface Props {
  className?: string;
  isOpen?: boolean;
  children?: React.ReactNode;
}

const Section: React.FC<Props> = ({ isOpen, children }) => {
  return <div className={isOpen ? "block" : "hidden"}>{children}</div>;
};

export default Section;
