import React from "react";

interface CardProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  extraRounded?: boolean;
  color ?: string;
  isWhite?: boolean;
}

export const Card: React.FC<CardProps> = ({
  className,
  style,
  children,
  extraRounded,
  color,
  isWhite,
}) => (
  <div
    className={`${
      extraRounded ? "rounded-custom" : "rounded-lg"
    } shadow ${isWhite ? "bg-white" : color }  overflow-hidden ${className}`}
    style={style}
  >
    {children}
  </div>
);
