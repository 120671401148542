import React from "react";

interface CardProps {
  width: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  text: string;
  percent?: number;
  isSidebar?: boolean;
  whiteBg?: boolean;
  extraLongWidth?: boolean;
  widthChosen?: number;
}

export const Tooltip: React.FC<CardProps> = ({
  width,
  children,
  text,
  whiteBg,
  isSidebar,
  percent,
  extraLongWidth,
  widthChosen = 300,
}) => {
  const tooltipWidth: number = isSidebar
    ? 80
    : extraLongWidth
    ? widthChosen
    : 200;
  return (
    <div className="relative text-sm w-70 z-50">
      <div
        className={`absolute whitespace-no-wrap ${
          whiteBg
            ? "bg-white p-12 shadow-lg"
            : "bg-grey-40 px-4 py-2 text-white"
        }  rounded flex items-center transition-all duration-150 bottom-2 ${
          isSidebar ? "text-center" : "text-left"
        }`}
        style={{ left: `${width}px`, width: tooltipWidth }}
      >
        <div
          className={`${isSidebar ? "inset-x-1/2" : "inset-x-1/4"} ${
            whiteBg ? "bg-white" : "bg-grey-40"
          } h-3 w-3 absolute bottom-0`}
          style={{ bottom: -3, transform: "rotate(45deg)" }}
        />
        {text}
        {children}
        {percent && (
          <span className="font-medium ml-2">{`${percent.toFixed(1)}%`}</span>
        )}
      </div>
    </div>
  );
};
