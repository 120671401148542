import React from "react";
import { useParams } from "react-router-dom";
import { Company } from "../../mock/NewTable";
import { BackNavbar } from "../../shared/Navigation/backNav";
import { Sidebar } from "../../shared/Navigation/Sidebar";
import { CompanyPattern } from "./CompanyPattern";
import { Error } from "../../error";

interface IPatternPageProps {
  companies: Company[];
}
export const PatternPage = ({ companies }: IPatternPageProps) => {
  const { compagnie } = useParams<any>();

  const company = companies.find(
    (company) => company.name.toLowerCase() === compagnie.toLowerCase()
  );
  return company ? (
    <div className="bg-lgrey ">
      <div className="lg:hidden block">
        <Error />
      </div>
      <div className="lg:block hidden">
        <Sidebar />
        <main className="lg:ml-36 lg:mr-4  ">
          <BackNavbar company={company} />
          <CompanyPattern company={company} />
        </main>
      </div>
    </div>
  ) : null;
};
