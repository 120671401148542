import React, {
  createContext,
  Dispatch,
  MouseEvent,
  ReactNode,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
interface PopperModalContextState {
  popperId: string;
  showingComponentId: number | null;
  depth: number;
  componentCloseOnOuterClickId: boolean;
}

enum ModalActionType {
  SHOW_POPPER = "SHOW_POPPER",
  HIDE_POPPER = "HIDE_POPPER",
}

interface ShowModalAction {
  type: ModalActionType.SHOW_POPPER;
  showingComponentId: number;
  componentCloseOnOuterClickId?: boolean;
}

interface HideModalAction {
  type: ModalActionType.HIDE_POPPER;
  hideComponentId?: number;
}

type ModalAction = ShowModalAction | HideModalAction;

const reducer = (
  state: PopperModalContextState,
  action: ModalAction
): PopperModalContextState => {
  if (action.type === "SHOW_POPPER") {
    const { showingComponentId, componentCloseOnOuterClickId = true } = action;
    if (
      state.showingComponentId === showingComponentId &&
      state.componentCloseOnOuterClickId === componentCloseOnOuterClickId
    ) {
      return state;
    } else {
      return {
        ...state,
        showingComponentId: showingComponentId,
        componentCloseOnOuterClickId: componentCloseOnOuterClickId,
      };
    }
  }
  if (action.type === "HIDE_POPPER") {
    if (
      state.showingComponentId === null ||
      (typeof action.hideComponentId !== "undefined" &&
        state.showingComponentId !== action.hideComponentId)
    ) {
      return state;
    } else {
      return {
        ...state,
        showingComponentId: null,
        componentCloseOnOuterClickId: true,
      };
    }
  }
  return state;
};

type HideCallback = () => void;

interface PopperModalContextProps {
  state: PopperModalContextState;
  dispatch: Dispatch<ModalAction>;
  nextDropDownId: () => number;
  OuterClick: (e: MouseEvent) => void;
  onHideCallback: (cb?: HideCallback) => void;
}

export const ModalContext = createContext<PopperModalContextProps>({
  state: {
    popperId: "popperContext-div",
    showingComponentId: null,
    componentCloseOnOuterClickId: true,
    depth: -1,
  },
  dispatch: () => {},
  nextDropDownId: () => 0,
  OuterClick: () => {},
  onHideCallback: () => {},
});

let containerDivIdCounter = 0;

export const ModalProvider = ({
  additionalCssClasses = "flex items-center justify-center",
  children,
  modalContainerDivId,
  positionCssClasses = "fixed inset-0",
}: {
  additionalCssClasses?: string;
  children: ReactNode;
  modalContainerDivId?: string;
  positionCssClasses?: string;
}) => {
  const [containerDivId] = useState(
    modalContainerDivId || `ModalContext-div-${containerDivIdCounter++}`
  );
  const onHideCallbackRef = useRef<undefined | HideCallback>(undefined);
  const setOnHideCallback = useCallback((cb?: HideCallback) => {
    onHideCallbackRef.current = cb;
  }, []);

  const {
    state: { depth, popperId },
  } = useContext(ModalContext);
  const [state, dispatch] = useReducer<
    Reducer<PopperModalContextState, ModalAction>
  >(reducer, {
    depth: depth + 1,
    popperId: containerDivId,
    showingComponentId: null,
    componentCloseOnOuterClickId: true,
  });
  const nextId = useRef(1);
  const getDropDownId = useCallback(() => {
    nextId.current = nextId.current + 1;
    return nextId.current - 1;
  }, []);
  const onDivClick = useCallback(() => {
    if (
      state.showingComponentId !== null &&
      state.componentCloseOnOuterClickId
    ) {
      dispatch({ type: ModalActionType.HIDE_POPPER });

      if (onHideCallbackRef.current) {
        onHideCallbackRef.current();
        setOnHideCallback(undefined);
      }
    }
  }, [dispatch, state, setOnHideCallback]);
  let parentLayerElement: HTMLElement | null = null;
  if (state.depth > 0) {
    parentLayerElement =
      typeof document !== "undefined"
        ? document.getElementById(popperId)
        : null;
  }

  const modalLayerDiv = (
    <div
      id={state.popperId}
      className={
        state.showingComponentId !== null
          ? `block ${positionCssClasses} bg-transparent z-50${
              state.componentCloseOnOuterClickId ? "" : " pointer-events-none"
            }${additionalCssClasses ? ` ${additionalCssClasses}` : ""}`
          : "hidden"
      }
      onClick={state.componentCloseOnOuterClickId ? onDivClick : undefined}
    />
  );

  return (
    <ModalContext.Provider
      value={{
        state,
        dispatch,
        nextDropDownId: getDropDownId,
        OuterClick: onDivClick,
        onHideCallback: setOnHideCallback,
      }}
    >
      <div>
        {parentLayerElement
          ? createPortal(modalLayerDiv, parentLayerElement)
          : modalLayerDiv}
        {children}
      </div>
    </ModalContext.Provider>
  );
};

export interface UseModalProps {
  closeOnOuterClick?: boolean;
  isVisible: boolean;
  modalContainerId: string;
  showModal: () => void;
  hideModal: (onlyForThisComponentId?: boolean) => void;
}

export interface UseModalOptions {
  closeOnOuterClick?: boolean;
  initialShowDropDown?: boolean;
  onHideModal?: () => void;
}

export const useModal = (options: UseModalOptions = {}): UseModalProps => {
  const {
    closeOnOuterClick = true,
    initialShowDropDown = false,
    onHideModal,
  } = options;
  const { state, dispatch, nextDropDownId, onHideCallback } =
    useContext(ModalContext);
  const [componentId] = useState(nextDropDownId());
  useEffect(() => {
    if (initialShowDropDown) {
      if (onHideModal) {
        onHideCallback(onHideModal);
      }
      dispatch({
        type: ModalActionType.SHOW_POPPER,
        showingComponentId: componentId,
        componentCloseOnOuterClickId: closeOnOuterClick,
      });
    }
  }, [
    onHideModal,
    closeOnOuterClick,
    componentId,
    dispatch,
    initialShowDropDown,
    onHideCallback,
  ]);
  const isVisible = useMemo(
    () => state.showingComponentId === componentId,
    [state, componentId]
  );
  const showModal = useCallback(() => {
    if (componentId !== null) {
      if (onHideModal) {
        onHideCallback(onHideModal);
      }
      dispatch({
        type: ModalActionType.SHOW_POPPER,
        showingComponentId: componentId,
        componentCloseOnOuterClickId: closeOnOuterClick,
      });
    }
  }, [dispatch, componentId, onHideModal, closeOnOuterClick, onHideCallback]);

  const hideModal = useCallback(
    (onlyForThisControlId = false) => {
      const hideAction: HideModalAction = {
        type: ModalActionType.HIDE_POPPER,
      };
      if (onlyForThisControlId) {
        hideAction.hideComponentId = componentId;
      }
      if (onHideModal) {
        onHideModal();
        onHideCallback(undefined);
      }
      dispatch(hideAction);
    },
    [dispatch, componentId, onHideModal, onHideCallback]
  );
  return {
    isVisible,
    modalContainerId: state.popperId,
    showModal,
    hideModal,
  };
};
