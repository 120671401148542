import React from "react";
import { Image } from "../../../uxApp/shared/UIElement/image";
import iPhone from "../../../assets/Landing/iphoneCircle.svg";
import arrowBlue from "../../../assets/Landing/arrowBlue.svg";
import arrowRed from "../../../assets/Landing/arrowRed.svg";
import twiggleArrow from "../../../assets/Landing/twiggleArrow.svg";

interface Data {
  icon: string;
  title: string;
  paragraph: string;
  pros: string;
  cons: string;
  percentage: number;
  color: string;
}
export const UxPractices = () => {
  const practices: Data[] = [
    {
      icon: arrowBlue,
      title: "Infinite scroll",
      paragraph: "Mostly used in news-feed-style content",
      pros: "Generating new content as you scroll",
      cons: "Finding the spot you left off",
      percentage: 90,
      color: "#575DF5",
    },
    {
      icon: arrowRed,
      title: "Video hero",
      paragraph: "Favorite option is interactive animation hero",
      pros: "Grabs attention and tells a story",
      cons: "Can slow down web performance",
      percentage: 30,
      color: "#E9001F",
    },
  ];

  return (
    <div className="flex lg:flex-row flex-col items-center">
      <img
        src={twiggleArrow}
        className="lg:absolute lg:block hidden"
        style={{
          left: "402px",
          bottom: "150px",
        }}
        alt=""
      />
      <Image
        additionalCssClasses={""}
        width={"lg:w-p416 w-p290"}
        height={"h-full"}
        src={iPhone}
        alt="photo"
        lazy={true}
      />
      <div
        style={{ maxWidth: "282px" }}
        className="xl:ml-61 lg:ml-30 lg:mt-0 mt-18"
      >
        <h2 className="text-Blue-100 font-title lg:text-left text-center text-4lg xl:mb-18 mb-16 font-bold leading-tight">
          UX patterns that is growing in practice
        </h2>
        {practices.map((practice, index) => (
          <div
            className="flex flex-col xl:mb-20 lg:mb-15 mb-8 last:mb-0"
            key={index}
          >
            <div className="inline-flex justify-between">
              <p className="text-Blue-100 font-title text-2lg font-bold">
                {practice.title}
              </p>
              <img src={practice.icon} alt="icon" />
            </div>
            <p className="text-Blue-100 mb-5">{practice.paragraph}</p>
            <p className="text-Blue-100 mb-5">
              <span className="font-bold">Pros: </span>
              {practice.pros}
            </p>
            <p className="text-Blue-100 mb-5">
              <span className="font-bold">Cons: </span>
              {practice.cons}
            </p>
            <div className="bg-grey-1000 h-2 w-full rounded">
              <div
                className="h-2 rounded"
                style={{
                  width: `${practice.percentage}%`,
                  backgroundColor: `${practice.color}`,
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
