import React from "react";
import { Link } from "react-router-dom";

import logo from "../assets/uxanalytic-logo.svg";

export const Error = () => {
  return (
    <section className="h-screen bg-white p-6">
      <div>
        <img src={logo} alt="logo" />
      </div>
      <div className="mt-24 text-center">
        <h1 className="mb-6 font-title text-5xl font-bold text-darkgrey">
          Opa!! <br /> Dont worry
        </h1>
        <p className="font-google text-darkgrey">
          UX Analytic is currently only available on desktop. For best
          experience try it on your computer.
        </p>
        <Link to="/" className="bg-transparent">
          <button
            type="button"
            style={{ outline: "none" }}
            className="bg-Red-400  text-white text-center font-title font-semibold py-2 px-4 rounded-full w-108 mt-12 h-30  shadow text-lg"
          >
            {"Take me back home"}
          </button>
        </Link>
      </div>
    </section>
  );
};
