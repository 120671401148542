import React from "react";
import { Link } from "react-router-dom";
import logowhite from "../../assets/ux-analytic-logo-white.svg";
import instagram from "../../assets/Landing/instagram.svg";
import { PageSection } from "../../uxApp/shared/style components/PageSection";

export const Footer = () => {
  const today = new Date();

  return (
    <PageSection
      bgColorClass={"bg-electricblue"}
      hideVerticalSpacing
      additionalCssClasses="py-8 lg:pt-20"
    >
      <div className="flex lg:justify-between lg:items-center lg:flex-row flex-col py-8 lg:mx-16">
        <div className="flex lg:flex-row flex-col items-center my-2">
          <div className="lg:mt-0 mt-20 mb-10 lg:mb-0">
            <img
              src={logowhite}
              className={"md:w-20 md:h-17 w-16 h-14"}
              alt="logo white"
            />
          </div>
          <div>
            <p className="text-white pl-4 text-sm md:text-lg mb-10 lg:mb-0">
              {`Copyright © ${today.getFullYear()} UX Analytic `}
            </p>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col items-center align-middle md:my-2">
          <div className="inline-block pl-2 mr-2 lg:mb-0 mb-10">
            <Link
              className="text-white md:pl-4 text-xs md:text-lg underline"
              to="/privacy"
            >
              {"Terms & Privacy"}
            </Link>
          </div>
          <div className="inline-block pl-2 mr-5 lg:mb-0 mb-10">
            <a
              className="text-white md:pl-4 text-xs md:text-lg underline"
              href="mailto:hello@uxanalytic.com"
            >
              {"Contact Us"}
            </a>
          </div>
          <div
            className={
              "border-none lg:border lg:border-l-2 lg:border-white flex flex-row lg:pl-8  justify-between lg:mb-0 mb-10"
            }
          >
            <a
              className={"w-14 h-auto"}
              href="https://www.instagram.com/uxanalytic/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={instagram} alt="instagram icon" />
            </a>
          </div>
        </div>
      </div>
    </PageSection>
  );
};
