import React from "react";

interface IImageProps {
  additionalCssClasses?: string;
  imgClass?: string;
  style?: React.CSSProperties;
  alt: string;
  src: string;
  width: string;
  height: string;
  lazy?: boolean;
}

export const Image = ({
  additionalCssClasses,
  style,
  alt,
  src,
  width,
  height,
  lazy,
  imgClass,
}: IImageProps) => {
  return (
    <div
      className={` 
     ${additionalCssClasses}`}
    >
      <img
        className={` ${width} ${height} ${imgClass}`}
        src={src}
        alt={alt}
        loading={lazy ? "lazy" : "eager"}
        style={style}
      />
    </div>
  );
};
