import React from "react";

import bestPracticeIcon from "../../assets/Landing/bestPractice.svg";
import buildingBlocksIcon from "../../assets/Landing/buildingBlocks.svg";
import productRefinementIcon from "../../assets/Landing/productRefinement.svg";

interface Data {
  icon: string;
  paragraph: string;
}
export const WhyUs = () => {
  const whyUxData: Data[] = [
    {
      icon: productRefinementIcon,
      paragraph: "Find design inspiration for your products.",
    },
    {
      icon: bestPracticeIcon,
      paragraph: "Identify design trends and products on the market.",
    },
    {
      icon: buildingBlocksIcon,
      paragraph: "View the building blocks behind websites and apps.",
    },
  ];

  return (
    <div className={"lg:mx-16 text-grey-500"}>
      <div className={"flex flex-col lg:flex-row text-center justify-center"}>
        {whyUxData.map((data, index) => (
          <div
            className="flex flex-row  items-center lg:py-13 lg:px-28 first:ml-0 last:mr-0 lg:border-r-2 border-r-0 lg:border-b-0 border-b-2 border-grey-990 last:border-0 pb-10 pt-6 lg:pt-0 first:pt-0 lg:pb-0 last:pb-0"
            key={index}
          >
            <div className={""}>
              <img
                src={data.icon}
                alt="icon"
                className={"md:w-28 md:h-28 w-24 h-24"}
              />
            </div>
            <div className={"text-left font-light w-106 ml-12"}>
              {data.paragraph}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
