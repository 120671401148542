import React, { Fragment, useState } from "react";
import Media from "react-media";
import Section from "../../../uxApp/shared/section";

import { DesignAdvice } from "./designAdvice";
import { StartupTips } from "./startupTips";
import { UxPractices } from "./uxPractices";

export const DesignPractices = () => {
  const [section, setSection] = useState<string>("UX practices");

  const isOpenSection = (name: string) => section === name;

  const toggleSection = (name: string) => {
    setSection(name);
  };

  return (
    <Fragment>
      <nav className="lg:flex hidden flex-row items-center justify-center mb-20 lg:order-1 order-2 lg:mt-0 mt-12">
        <div
          onClick={() => toggleSection("UX practices")}
          onKeyPress={() => toggleSection("UX practices")}
          tabIndex={0}
          role="button"
          className={`rounded-full mx-4 px-8 py-4 ${
            isOpenSection("UX practices")
              ? "bg-purpleblue text-white outline-none"
              : " bg-transparent border-2 text-grey-985 border-gray-400 border-solid"
          } outline-none`}
        >
          UX practices
        </div>

        <div
          onClick={() => toggleSection("Startup tips")}
          onKeyPress={() => toggleSection("Startup tips")}
          tabIndex={0}
          role="button"
          className={`rounded-full mx-4 px-8 py-4 ${
            isOpenSection("Startup tips")
              ? "bg-purpleblue text-white outline-none"
              : " bg-transparent border-2 text-grey-985 border-gray-400 border-solid"
          } outline-none`}
        >
          Startup tips
        </div>
      </nav>
      <div className="flex lg:flex-row flex-col items-center justify-center">
        <div className="lg:w-p306 lg:ml-20 lg:text-left text-center lg:order-2 order-1">
          <p className="text-Purple-825">Design Practices</p>
          <h2 className="text-Blue-100 font-title xl:text-5xl text-3xl leading-tight font-bold">
            Here are some design practices we found
          </h2>
          <p className="text-grey-500 xl:text-2lg text-lg">
            Our insights are based on 100+ companies we have analysed.
          </p>
        </div>
        <Media queries={{ small: { maxWidth: 768 } }}>
          {(matches) =>
            matches.small && (
              <nav className="flex lg:hidden flex-row items-center justify-center mb-20 lg:order-1 order-2 lg:mt-0 mt-12">
                <div
                  onClick={() => toggleSection("UX practices")}
                  onKeyPress={() => toggleSection("UX practices")}
                  tabIndex={0}
                  role="button"
                  className={`rounded-full mx-4 px-8 py-4 ${
                    isOpenSection("UX practices")
                      ? "bg-purpleblue text-white outline-none"
                      : " bg-transparent border-2 text-grey-985 border-gray-400 border-solid"
                  } outline-none`}
                >
                  UX practices
                </div>

                <div
                  onClick={() => toggleSection("Startup tips")}
                  onKeyPress={() => toggleSection("Startup tips")}
                  tabIndex={0}
                  role="button"
                  className={`rounded-full mx-4 px-8 py-4 ${
                    isOpenSection("Startup tips")
                      ? "bg-purpleblue text-white outline-none"
                      : " bg-transparent border-2 text-grey-985 border-gray-400 border-solid"
                  } outline-none`}
                >
                  Startup tips
                </div>
              </nav>
            )
          }
        </Media>
        <div className="order-3">
          <div className="relative lg:ml-7">
            <Section isOpen={isOpenSection("UX practices")}>
              <UxPractices />
            </Section>
          </div>
          <div className="relative lg:ml-7">
            <Section isOpen={isOpenSection("Startup tips")}>
              <StartupTips />
            </Section>
          </div>
        </div>
      </div>
      <div className="relative">
        <DesignAdvice />
      </div>
    </Fragment>
  );
};
