import "firebase/compat/firestore";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  User,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
  setDoc,
  addDoc,
  query,
  where,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import dotenv from "dotenv";
import "firebase/auth";
import { useEffect, useState } from "react";
import { UserProfile } from "../../mock/NewTable";

dotenv.config();

export const app = initializeApp({
  apiKey: process.env.REACT_APP_API_KEY as string,
  authDomain: process.env.REACT_APP_AUTHDOMAIN as string,
  projectId: process.env.REACT_APP_PROJECT_ID as string,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET as string,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID as string,
  appId: process.env.REACT_APP_APP_ID as string,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID as string,
});
export const db = getFirestore();
export const auth = getAuth();
export const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, provider);
    const user = res.user;
    const docRef = doc(db, "users", user.uid);
    const data: UserProfile = {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
    };
    await setDoc(docRef, data);
  } catch (err) {
    console.error(err);
  }
};

export type USER = User | null;
export function useAuth() {
  const [currentUser, setCurrentUser] = useState<USER>(null);
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return unsub;
  }, []);
  return currentUser;
}

export {
  getFirestore,
  collection,
  getDocs,
  doc,
  onSnapshot,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  setDoc,
  addDoc,
  getDoc,
  query,
  where,
};
